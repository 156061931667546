export function validUrl(url: string, validPrefixes: string[]): boolean {
  const prefixesPattern = validPrefixes.join("|");
  // eslint-disable-next-line security/detect-non-literal-regexp
  const regex = new RegExp(`\/(${prefixesPattern})\/\\d+`);
  return regex.test(url);
}

export function isXylemeSyndicateUrl(url: string): boolean {
  //should return true for URLs that start with https:// and include cisco.bravais.com
  // eslint-disable-next-line security/detect-unsafe-regex
  const urlPattern = /^https:\/\/(?:.*[-.])?cisco\.bravais\.com(?:\/.*)?$/;
  return urlPattern.test(url);
}
