import type { Emitter } from "mitt";
import mitt from "mitt";

type Events = {
  applyCLC: any;
  cartTelemetry: any;
  changeTrack: any;
  changePayment: any;
  clearPath: any;
  closeULA: any;
  closeULAWitthoutAccept: any;
  deleteTaxIdentifier: any;
  getTrack: any;
  goToBilling: any;
  goToPayment: any;
  modalOpen: any;
  nextStep: any;
  openAiPrompt: any;
  openCartModal: any;
  opsLoader: any;
  orderTotal: any;
  refreshBasket: any;
  resetModal: any;
  resetULA: any;
  seedAiSearch: any;
  showBanner: any;
  showCart: any;
  showSubscribe: any;
  step_1_error: any;
  step_2_error: any;
  step_3_error: any;
  step_4_error: any;
  submitAiSearch: any;
  syncTopics: any;
  taxExempt: any;
  taxIdentifier: any;
  updatesurveyCompleted: any;
  updateBillingAddress: any;
  updateCartInfo: any;
  updateCompany: any;
  updatePaymentMethod: any;
  updatePaymentSource: any;
  updatePromptBtnState: any;
  updateTaxCertificate: any;
  customerData: any;
  updateQty: any;
  validation: any;
  updateUserProgress: any;
  getNewActionTutorial: any;
  cardError: any;
};

export const EventBus: Emitter<Events> = mitt<Events>();
